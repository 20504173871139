


import { Component, Vue } from "vue-property-decorator";
import { Login } from "@/shared/dtos/login";
import clienteModule from "@/store/modules/cliente-module";
import { cliente } from "@/shared/dtos/cliente";

@Component({
  $_veeValidate: { validator: "new" },
})
export default class LoginFormulario extends Vue {
  public appTitle = process.env.VUE_APP_TITLE;

  public login!: Login;
  public hide: boolean = true;
  public clientes: cliente[] = [];
  public show_modal: boolean = false;

  public mounted() {
    this.login = new Login();
    setTimeout(() => (this.hide = false), 1);
  }

  public submit() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        clienteModule.get_mis_clientes(this.login.UserName).then((res) => {
          this.clientes = res ? res.map((x: any) => new cliente(x)) : [];

          if (this.clientes.length > 1) {
            this.show_modal = true;
          } else {
            this.tryLogin();
          }
        });

        //
      }
    });
  }
  public finishsubmit(id_cliente: number) {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.tryLogin(id_cliente);
      }
    });
  }

  public async tryLogin(id_cliente: number = -1) {
    this.login.id_cliente = id_cliente;
    const authorizationService = await import(
      "@/shared/services/AuthorizationService"
    );
    authorizationService.authorizationService
      .login(this.login)
      .then(this.loginResult.bind(this));
  }

  private async loginResult(loginOk: Login) {
    if (loginOk.Success) {
      this.hide = true;
      if (loginOk.chg_pwd) {
        setTimeout(() => this.LostPwd(), 250);
      } else {
        setTimeout(() => this.routeToMain(), 250);
      }
    } else {
      const MessageService = await import("@/shared/services/message-service");

      MessageService.MessageService.toast(
        "Usuario o Clave incorrectos",
        MessageService.MessageService.TypeError
      );
    }
  }

  private routeToMain() {
    this.$router.push("/");
  }
  private async LostPwd() {
    const routernames = await import("@/router/routernames");
    this.$router.push({
      name: routernames.RouterNames.lostpwd,
      params: { email: this.login.UserName },
    });
  }
}
